<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <sidebar-extract :current="3"></sidebar-extract>
          <div class="page__content">
            <h1 class="page__title">Dados da Entidade</h1>
            <span class="tabs__anchor">
              Informações Públicas da Entidade
            </span>
            <div class="d-flex avatar-container">
              <div class="avatar">
                <img v-if="user.avatar" :src="user.avatar" alt="" />
                <span v-else> {{ user.name[0] }} </span>
              </div>
              <div class="d-flex column avatar-info">
                <b>{{ user.name }}</b>
                <span>{{ entity.url }}</span>
      <router-link
        to="/perfil"
        class="header__access--anchor"
        title="Editar entidade"
      >
                <span>
                  Editar entidade
                </span>
      </router-link>
              </div>
            </div>

            <div class="entity-about">
              <h3>Sobre</h3>

              <textarea class="about-text-field" v-model="entity.description"></textarea>
            </div>

            <div class="entity-photos">
              <h3>Fotos</h3>
              <span @click="changePhoto" class="link" href="#">Adicionar fotos</span>
              <input
              @change="handleFile"
              type="file"
              hidden
              ref="avatarFile"
              accept="image/png,image/jpeg"
              />
              <silent-box v-if="entity.images.length>0"
                class="entity-photos-gallery"
                :gallery="entity.images"
              ></silent-box>
            </div>

            <div class="col-12">
              <button type="button" class="button_outline" @click="submit()">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <edit-data
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      :entityData.sync="entity"
    ></edit-data>
  </main>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import VueSilentbox from 'vue-silentbox'

const defaultForm = {
  id: null,
  description: ''
}

Vue.use(VueSilentbox)

export default {
  components: {
    sidebarExtract: () => import('@/components/sidebar-extracts'),
    editData: () => import('./edit-data')
  },
  data: () => ({
    loading: false,
    form: { ...defaultForm, file: {}, id: '' },
    id: '',
    entity: '',
    images: [
      {
        src:
          'https://www.uopeccan.org.br/wp-content/uploads/2017/06/IMG_7950-1024x683.jpg',
        description: 'Foto 1'
      },
      {
        src:
          'https://www.uopeccan.org.br/wp-content/uploads/2018/03/MG_7546-79.jpg',
        description: 'Foto 2'
      },
      {
        src:
          'https://cidadeportal.com.br/arquivos/9d892e3c0b0420b46de899c88869f52f/noticias/07022019153217f01.jpg',
        description: 'Foto 3'
      }
    ],
    modal: false,
    entityData: { name: 'Uopeccan' }
  }),
  computed: {
    ...mapGetters('user', ['user'])
  },
  methods: {
    ...mapActions('user', ['getFromStorage']),
    changePhoto () {
      console.log('aqui')
      this.$refs.avatarFile.click()
    },
    handleFile (e) {
      console.log('aqui 1')
      if (this.loading) return
      this.form.file = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['image/png', 'image/jpeg']
      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PNG e JPG.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }
      console.log('aqui3')
      this.form.file = file
    },
    async getHits () {
      this.loading = true
      const { id } = this.user
      let url = `entities/getEntity?id=${id}`
      const { startDate, endDate } = this

      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`
      }

      const { data } = await this.$http.get(url)
      this.entity = data.data
      this.loading = false
    },
    async created () {
      await this.getFromStorage()
    },
    async submit () {
      this.loading = true
      try {
        const id = this.user.id
        this.form.id = this.user.id
        this.form.description = this.entity.description
        const url = `entities/update/${id}`
        const method = id ? 'PUT' : 'POST'

        await axios.request({ method, url, data: this.form })

        swal({
          title: 'Sucesso!',
          text: `Conta ${id ? 'atualizada' : 'cadastrada'} com sucesso.`,
          icon: 'success',
          timer: 4000
        }).then(() => {
          this.form = { ...defaultForm }
        })
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: error.response.data.message,
          icon: 'error',
          button: 'Ok'
        })
      } finally {
        this.loading = false
      }
    },
    async sendFile () {
      const form = new FormData()
      form.append('file', this.form.file)
      form.id = this.user.id
      console.log(form)
      const url = 'auth/user/photo'
      await this.$http.post(url, form)
      window.location.reload()
    }

  },
  watch: {
    'form.file': {
      deep: true,
      handler () {
        this.sendFile()
      }
    }
  },
  mounted () {
    this.getHits()
  }
}
</script>

<style scoped>
.avatar-container {
  margin-top: 3rem;
  align-items: center;
}

.avatar-info * {
  margin-bottom: 0.5rem;
}

.avatar {
  overflow: hidden;
  width: 120px;
  height: 120px;
  background: #47be71;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.avatar span {
  font-size: 3rem;
  color: white;
  font-weight: bold;
}

.entity-about {
  margin-top: 3rem;
}

.about-text-field {
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 8rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow: hidden;
}

.entity-photos {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
button {
  margin-top: 1rem;
  padding: 1rem;
  font-weight: bold;
  min-width: 25%;
}
</style>

<style>
.entity-photos-gallery {
  width: 100%;
}

.entity-photos-gallery .silentbox-item {
  margin: 4px;
  width: calc(20% - 8px);
}
</style>
